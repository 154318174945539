html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grecaptcha-badge {
  visibility: hidden;
}
.grecaptcha-box {
  padding-left: 0px;
}
.grecaptcha-box .no-hide {
  position: relative !important;
  visibility: visible !important;
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 0 !important;
}
.grecaptcha-box .no-hide:hover {
  width: 256px !important;
}

@media (max-width: 576px) {
  html,
  body,
  #root {
    height: unset;
    min-height: 100vh;
  }
}
